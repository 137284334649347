import React, { useEffect, useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import Loader from "../Loader";
import { useLoading, useLocalStorage } from "../../hooks";
import MainWrapper from "../MainWrapper";
import Header from "../Dashboard/Header";
import Sidebar from "../Dashboard/Sidebar";
import { toast } from "react-toastify";
import front_arrow from "../../assets/icons/front-arrow.png";
import {
  FetchBot,
  MarginCall,
  UpdateBot,
  FetchUser,
  FetchUserBots,
  CustomNotification,
  NotifyUser,
  DeleteBot,
  BanUser,
  FetchUserReferrals,
  UpdateUserReferredBy,
  FetchBots,
} from "../../API";
import { useLocation } from "react-router-dom";
import Modal from "../Modal";
import useDisclosure from "../../hooks/useDisclosure";
import { BOT_ERROR_STATUS, formatDate } from "../../utilities";

function Details() {
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [authToken] = useLocalStorage("authToken", "");
  const [userBots, setUserBots] = useState([]);
  const [selectedBot, setSelectedBot] = useState({});
  const [manualProfit, setmanualProfit] = useState(0);
  const [fee, setFee] = useState(selectedBot.fee);
  const [user, setUser] = useState({});
  const [message, setMessage] = useState("");
  const [userReferrals, setUserReferrals] = useState([]);
  const [referredBy, setReferredBy] = useState("");
  const [commaId, setCommaId] = useState("");

  const { isOpen, onClose, onOpen, onToggle, setOpen } = useDisclosure();
  const {
    isOpen: isOpen3,
    onClose: onClose3,
    onOpen: onOpen3,
    onToggle: onToggle3,
    setOpen: setOpen3,
  } = useDisclosure();
  const {
    isOpen: isOpen1,
    onClose: onClose1,
    onOpen: onOpen1,
    onToggle: onToggle1,
    setOpen: setOpen1,
  } = useDisclosure();
  const {
    isOpen: isOpen2,
    onClose: onClose2,
    onOpen: onOpen2,
    onToggle: onToggle2,
    setOpen: setOpen2,
  } = useDisclosure();

  const location = useLocation();
  // Parse the search string to extract query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get individual query parameters by key
  const user_id = queryParams.get("user_id");

  const updateUserReferredBy = async () => {
    if (referredBy === "none" || referredBy === "") return;
    if (!user_id) return;
    startLoading();
    const res = await UpdateUserReferredBy({ user_id, referredBy }, authToken);
    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
    } else {
      toast.error(res?.message || "Unable to update referred by", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const banUser = async () => {
    if (!user_id) return;
    startLoading();
    const res = await BanUser({ user_id }, authToken);
    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
      fetchUser();
      // window.open("/bots-management", "_self");
    } else {
      toast.error(res?.message || "Unable to ban user", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const deleteBot = async () => {
    if (!user_id) return;
    startLoading();
    const res = await DeleteBot({ bots: [selectedBot] }, authToken);
    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
      fetchUser();
      setSelectedBot({});
    } else {
      toast.error(res?.message || "Unable to ban user", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const update3CommasId = async () => {
    if (!selectedBot._id) return;
    startLoading();
    const res = await UpdateBot(
      {
        comma_id: commaId,
        bot_id: selectedBot._id,
      },
      authToken
    );
    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const updateBotProfit = async () => {
    if (!selectedBot._id) return;
    if (manualProfit === 0) {
      toast.error("Please enter a valid amount", { position: "top-center" });
      return;
    }
    startLoading();
    const res = await UpdateBot(
      {
        manualProfit: parseFloat(manualProfit),
        bot_id: selectedBot._id,
      },
      authToken
    );
    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
      setmanualProfit(0);
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };
  const updateBotFee = async () => {
    if (!selectedBot._id) return;
    startLoading();
    const res = await UpdateBot(
      {
        bot_id: selectedBot._id,
        fee: parseFloat(fee),
      },
      authToken
    );
    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const handleMarginCall = async (status) => {
    if (!selectedBot._id) return;
    startLoading();
    const res = await MarginCall(
      {
        bots: [{ _id: selectedBot._id, status }],
      },
      authToken
    );

    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const notifyUser = async (bot_id, error) => {
    startLoading();
    const res = await NotifyUser({ bot_id: bot_id, error: error }, authToken);
    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
    } else {
      toast.error(res?.message || "Error occured", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const customNotification = async () => {
    if (!message) {
      toast.error("Please enter a message", { position: "top-center" });
      return;
    }
    startLoading();
    const res = await CustomNotification({ user_id, message }, authToken);
    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
    } else {
      toast.error(res?.message || "Error occured", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const fetchBot = async (bot_id) => {
    startLoading();
    const res = await FetchBot(authToken, bot_id);
    console.log(res);
    if (res?.success) {
      setSelectedBot(res.bot);
      setCommaId(res.bot.commaId ?? "");
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const fetchUser = async () => {
    startLoading();
    const [userRes, botsRes, referralsRes] = await Promise.all([
      FetchUser({ user_id, authToken }),
      FetchUserBots(user_id, authToken),
      FetchUserReferrals(user_id, authToken),
    ]);

    if (userRes?.success && botsRes?.success) {
      setUser(userRes.user);
      setUserBots(botsRes.bots);
      setUserReferrals(referralsRes.referrals);
      setReferredBy(
        userRes.user.referrer?.email ? userRes.user.referrer?.email : "none"
      );

      toast.success(userRes.message, { position: "top-center" });
    } else {
      toast.error(
        userRes?.message || botsRes?.message || "Unable to fetch data",
        {
          position: "top-center",
        }
      );
    }

    stopLoading();
  };

  useEffect(() => {
    if (!authToken) {
      toast.error("Please sign in to continue", { position: "top-center" });
      setTimeout(() => {
        window.open("/", "_self");
      }, 3000);
    } else {
      fetchUser();
    }
  }, []);

  useEffect(() => {
    setFee(selectedBot.fee);
  }, [selectedBot]);

  return (
    <MainWrapper>
      <Sidebar active={{ id: "2", href: "/bots-management" }} />
      <Header />
      <Wrapper style={"px-4 py-4 rounded-xl relative bg-[#292C3C]"}>
        <Loader isLoading={isLoading} />
        <div
          onClick={() => window.open(`/bots-management`, "_self")}
          className="flex items-center gap-4 cursor-pointer w-[15%] py-2 mb-10"
        >
          <img src={front_arrow} className="w-8 rotate-[180deg]" />
          <span className="font-semibold whitespace-nowrap">All Bots</span>
        </div>

        {user.name && (
          <>
            <div className="flex w-full gap-20 mb-20">
              <div className="w-[30%]">
                <div className="flex items-center justify-evenly mb-10">
                  <p className="">Name:</p>
                  <p className="">{user.name}</p>
                </div>
              </div>
              <div className="w-full">
                <div className="flex items-center justify-start gap-5  mb-10">
                  <p className="">Active Bots:</p>
                  {userBots.map((bot) => (
                    <button
                      onClick={() => fetchBot(bot._id)}
                      className={`${
                        selectedBot._id === bot._id
                          ? "bg-[#62688f] text-white"
                          : "bg-[#d9d9d9] text-black"
                      } w-[100px] px-4 py-2 rounded-md text-md`}
                    >
                      {bot.strategy}
                    </button>
                  ))}
                </div>
                <div className="flex gap-20 items-start relative ">
                  <p className="">Referrals:</p>

                  <div className="">
                    <p className="mb-5">Email:</p>
                    <div className="bg-[#393C49] w-[200px] h-[300px]">
                      {userReferrals?.map((referral) => (
                        <p className="p-2">{referral.email}</p>
                      ))}
                    </div>
                  </div>

                  <div className="">
                    <p className="mb-5">Date Registered:</p>
                    <div className="bg-[#393C49] w-[200px] h-[300px]">
                      {userReferrals.map((referral) => (
                        <p className="p-2">
                          {formatDate(referral?.createdAt, "MM-DD-YY. hh:mm a")}
                        </p>
                      ))}
                    </div>
                  </div>

                  <div className="">
                    <p className="mb-5">Referred by:</p>
                    <button
                      onClick={updateUserReferredBy}
                      className="bg-[#d9d9d9] text-black w-[100px] px-4 py-2 rounded-md text-md"
                    >
                      Edit
                    </button>
                  </div>

                  <div className="">
                    {/* <input
                      className="text-black"
                      value={user.referrer ?? "none"}
                    /> */}
                    <input
                      type="email"
                      placeholder={`${referredBy}`}
                      value={referredBy}
                      onChange={(e) => setReferredBy(e.target.value)}
                      className="outline-none bg-transparent p-2 text-white border-b-[0.5px] border-[#d9d9d9]"
                    />
                  </div>
                  {!user.isBanned && (
                    <button
                      onClick={onOpen}
                      className="bg-[#b60000] w-[200px] p-2 absolute bottom-0 right-10"
                    >
                      BAN ACCOUNT
                    </button>
                  )}
                  {user.isBanned && (
                    <button
                      onClick={onOpen3}
                      className="bg-[#d9d9d9] text-black  w-[200px] p-2 absolute bottom-0 right-10"
                    >
                      UNBAN ACCOUNT
                    </button>
                  )}
                </div>
              </div>
            </div>

            {selectedBot._id && (
              <div className="flex w-full gap-20">
                <div
                  className="w-[40%] flex justify-between
               items-start gap-5"
                >
                  <div className="">
                    <p className="mb-5">Status:</p>
                    <p className="">
                      {selectedBot.status === "ACTIVE" ? "ENABLED" : "DISABLED"}
                    </p>

                    <p className="mt-24">3Commas ID:</p>
                  </div>

                  <div className="flex flex-col">
                    <div className="flex flex-col items-center gap-5">
                      <p className="">Profit Share (%):</p>
                      <input
                        type="text"
                        placeholder={`${fee}%`}
                        value={fee}
                        onChange={(e) => setFee(e.target.value)}
                        className="outline-none bg-[#d9d9d9] p-2 text-[#151924] w-[50px] text-center rounded-lg"
                      />
                      <button
                        onClick={updateBotFee}
                        className="bg-[#d9d9d9] text-black  px-4 py-2 rounded-md text-md"
                      >
                        Save
                      </button>
                    </div>

                    <div className="mt-5 flex flex-col items-center justify-center">
                      <input
                        type="text"
                        placeholder={`${commaId}`}
                        value={commaId}
                        onChange={(e) => setCommaId(e.target.value)}
                        className="outline-none bg-transparent p-2 text-white border-b-[0.5px] border-[#d9d9d9]"
                      />

                      <button
                        onClick={update3CommasId}
                        className="bg-[#d9d9d9] text-black mt-3  px-4 py-2 rounded-md text-md"
                      >
                        Edit
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col items-center gap-5">
                    <p className="">Manual Trade ($):</p>
                    <input
                      type="number"
                      placeholder=""
                      value={manualProfit ?? 0}
                      onChange={(e) => setmanualProfit(e.target.value)}
                      className="outline-none bg-[#d9d9d9] p-2 text-[#151924] w-[70px] rounded-lg text-center"
                    />
                    <button
                      onClick={updateBotProfit}
                      className="bg-[#d9d9d9] text-black  px-4 py-2 rounded-md text-md"
                    >
                      Add
                    </button>
                  </div>
                </div>

                <div className="w-full flex items-start gap-20">
                  <div className="flex flex-col items-start gap-5">
                    <p className="">Notifications:</p>
                    <button
                      onClick={onOpen1}
                      className="bg-[#d9d9d9] text-black  px-4 py-2 rounded-md text-md"
                    >
                      Margin Call
                    </button>
                    <button
                      onClick={() =>
                        notifyUser(
                          selectedBot._id,
                          BOT_ERROR_STATUS.INSUFFICIENT_FUNDS
                        )
                      }
                      className="bg-[#d9d9d9] text-black  px-4 py-2 rounded-md text-md"
                    >
                      Insufficient Funds
                    </button>
                    <button
                      onClick={() =>
                        notifyUser(selectedBot._id, BOT_ERROR_STATUS.BAD_API)
                      }
                      className="bg-[#d9d9d9] text-black  px-4 py-2 rounded-md text-md"
                    >
                      Expired / Bad Api Keys
                    </button>

                    <button
                      onClick={() =>
                        notifyUser(
                          selectedBot._id,
                          BOT_ERROR_STATUS.NO_TRADE_PERMISSION
                        )
                      }
                      className="bg-[#d9d9d9] text-black  px-4 py-2 rounded-md text-md"
                    >
                      Missing API Trade Permissions
                    </button>
                  </div>

                  <div className="flex flex-col items-start gap-5">
                    <p className="">Custom Notification:</p>
                    <textarea
                      name=""
                      id=""
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="w-[350px] h-[170px] text-black bg-[#d9d9d9] p-2 rounded-lg"
                    ></textarea>
                    <button
                      onClick={customNotification}
                      className="bg-[#d9d9d9] text-black  px-4 py-2 rounded-md text-md float-right"
                    >
                      Send
                    </button>
                  </div>

                  <button
                    onClick={onOpen2}
                    className="text-[#b60000] text-xl w-[200px] p-2 absolute bottom-0 right-10"
                  >
                    Delete Bot
                  </button>
                </div>
              </div>
            )}
          </>
        )}

        <Modal
          closeModal={onClose1}
          isOpen={isOpen1}
          onOpen={onOpen1}
          onToggle={onToggle1}
        >
          <div className="w-[310px] p-5 bg-[#5b5b5b]">
            <p className="mb-5 text-white ">
              Are you sure you want to send a margin call to this bot?
            </p>
            <div className="flex justify-evenly items-center">
              <button
                onClick={() => {
                  handleMarginCall(true);
                  onClose1();
                }}
                className="bg-[#d9d9d9] w-[100px] px-4 py-2 rounded-md text-sm"
              >
                Yes
              </button>
              <button
                onClick={onClose1}
                className="bg-[#d9d9d9] w-[100px] px-4 py-2 rounded-md text-sm"
              >
                No
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          closeModal={onClose2}
          isOpen={isOpen2}
          onOpen={onOpen2}
          onToggle={onToggle2}
        >
          <div className="w-[310px] p-5 bg-[#5b5b5b]">
            <p className="mb-5 text-white ">
              Are you sure you want to delete this bot?
            </p>
            <div className="flex justify-evenly items-center">
              <button
                onClick={() => {
                  deleteBot();
                  onClose2();
                }}
                className="bg-[#b60000] text-white w-[100px] px-4 py-2 rounded-md text-sm"
              >
                Yes
              </button>
              <button
                onClick={onClose2}
                className="bg-[#d9d9d9] w-[100px] px-4 py-2 rounded-md text-sm"
              >
                No
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          closeModal={onClose3}
          isOpen={isOpen3}
          onOpen={onOpen3}
          onToggle={onToggle3}
        >
          <div className="w-[310px] p-5 bg-[#5b5b5b]">
            <p className="mb-5 text-white ">
              Are you sure you want to unban this user?
            </p>
            <div className="flex justify-evenly items-center">
              <button
                onClick={() => {
                  banUser();
                  onClose3();
                }}
                className="bg-[#d9d9d9]   w-[100px] px-4 py-2 rounded-md text-sm"
              >
                Yes
              </button>
              <button
                onClick={onClose3}
                className="bg-[#d9d9d9] w-[100px] px-4 py-2 rounded-md text-sm"
              >
                No
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          closeModal={onClose}
          isOpen={isOpen}
          onOpen={onOpen}
          onToggle={onToggle}
        >
          <div className="w-[310px] p-5 bg-[#5b5b5b]">
            <p className="mb-5 text-white ">
              Are you sure you want to ban this user?
            </p>
            <div className="flex justify-evenly items-center">
              <button
                onClick={() => {
                  banUser();
                  onClose();
                }}
                className="bg-[#b60000] text-white w-[100px] px-4 py-2 rounded-md text-sm"
              >
                Yes
              </button>
              <button
                onClick={onClose}
                className="bg-[#d9d9d9] w-[100px] px-4 py-2 rounded-md text-sm"
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      </Wrapper>
    </MainWrapper>
  );
}

export default Details;
