import React, { useEffect, useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import Loader from "../Loader";
import { useLoading, useLocalStorage } from "../../hooks";
import MainWrapper from "../MainWrapper";
import Header from "../Dashboard/Header";
import Sidebar from "../Dashboard/Sidebar";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import Modal from "../Modal";
import useDisclosure from "../../hooks/useDisclosure";
import {
  BOT_ERROR_STATUS,
  formatDate,
  maskString,
  style_select_picker,
} from "../../utilities";
import copy_icon from "../../assets/icons/copy.png";
import {
  ActivateBot,
  DisableBot,
  FetchBots,
  DeleteBot,
  MarginCall,
  NotifyUser,
} from "../../API";

function Main() {
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [authToken] = useLocalStorage("authToken", "");
  const [bots, setBots] = useState([]);
  const [comma_id, setcomma_id] = useState({});
  const [selected, setSelected] = useState("");
  const [error, setError] = useState([{ id: "1", name: "Insufficient funds" }]);
  const [marginCall, setMarginCall] = useState();
  const [checked, setChecked] = useState([]);

  const { isOpen, onClose, onOpen, onToggle, setOpen } = useDisclosure();

  const clickTable = (item) => {
    return;
    window.open(`/bots-activation/details?bot_id=${item._id}`, "_self");
  };

  const copyTextToClipboard = (value, feedback) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        toast.success(feedback + " copied", {
          position: "top-center",
        });
        // Optionally, you can provide feedback to the user that the text has been copied
      })
      .catch((error) => {
        console.error("Error copying text:", error);
        // Handle any errors that may occur during copying
      });
  };

  const enableBot = async (item) => {
    console.log(comma_id[item._id]);
    if (!comma_id[item._id]) {
      toast.error("Please enter 3COMMA ID", { position: "top-center" });
      return;
    }
    startLoading();
    const res = await ActivateBot(
      { bot_id: item._id, comma_id: comma_id[item._id] },
      authToken
    );
    if (res?.success) {
      fetchBots();
      toast.success("Success", { position: "top-center" });
    } else {
      toast.error(res?.message || "Error occured", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const disableBot = async (item) => {
    startLoading();
    const res = await DisableBot({ _id: item._id }, authToken);
    if (res?.success) {
      fetchBots();
      toast.success("Success", { position: "top-center" });
    } else {
      toast.error(res?.message || "Error occured", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const notifyUser = async (item, error) => {
    startLoading();
    const res = await NotifyUser(
      { bot_id: item._id, error: error[0].label },
      authToken
    );
    if (res?.success) {
      fetchBots();
      toast.success(res.message, { position: "top-center" });
    } else {
      toast.error(res?.message || "Error occured", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const fetchBots = async () => {
    startLoading();
    const res = await FetchBots(authToken);
    if (res?.success) {
      setBots(
        res.bots.filter((e) => {
          return e.apiKey !== "" || e.apiKey === undefined;
        })
      );
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  useEffect(() => {
    if (!authToken) {
      toast.error("Please sign in to continue", { position: "top-center" });
      setTimeout(() => {
        window.open("/", "_self");
      }, 3000);
    } else {
      fetchBots();
    }
  }, []);

  const handleDelete = async () => {
    if (checked.length < 1) return;
    startLoading();
    const res = await DeleteBot({ bots: checked.map((e) => e._id) }, authToken);
    if (res?.success) {
      setBots(res.bots);
      setChecked([]);
      toast.success("Success", { position: "top-center" });
    } else {
      toast.error(res?.message || "Error occured", {
        position: "top-center",
      });
    }
    stopLoading();
    onClose();
  };

  return (
    <MainWrapper>
      <Sidebar active={{ id: "3", href: "/bots-activation" }} />
      <Header />
      <Wrapper style={"px-4 py-4 rounded-xl relative bg-[#292C3C]"}>
        <Loader isLoading={isLoading} />

        <div className="bg-[#151924] py-8 px-6 rounded-xl overflow-x-auto min-h-80">
          <button
            onClick={() => {
              if (checked.length < 1) return;
              onOpen();
            }}
            className="px-4 py-2 text-black bg-[#d9d9d9] mb-5"
          >
            Delete
          </button>
          {/* <div className="w-[20%] py-4 flex gap-2 items-center">
            {checked.length > 0 && (
              <>
                <span className="">Margin call:</span>
                <Select
                  //disabled={item.intent == "FULFILLED" ? true : false}
                  style={style_select_picker}
                  options={[
                    {
                      id: "1",
                      name: "Enabled",
                      status: true,
                    },
                    {
                      id: "2",
                      name: "Disabled",
                      status: false,
                    },
                  ]}
                  labelField="name"
                  valueField="id"
                  values={marginCall}
                  placeholder="Select"
                  dropdownHeight={"110px"}
                  separator={false}
                  onChange={(values) => {
                    //checked.map((e)=>e)
                    handleMarginCall();
                    //notifyUser(item, values);
                  }}
                  backspaceDelete={false}
                  keepSelectedInList={true}
                />
              </>
            )}
          </div> */}
          <table className="bg-primary rounded-lg min-w-full ">
            <thead>
              <tr className="border-b-[0.5px] border-[#fff] whitespace-nowrap">
                <th className="text-left px1 py-4">{""}</th>
                <th className="text-left px-4 py-4">User</th>
                <th className="text-left px-4 py-4">Strategy</th>
                <th className="text-left px-4 py-4">Status</th>
                {/* <th className="text-left px-4 py-4">Request</th> */}
                <th className="text-left px-4 py-4">Date created</th>
                <th className="text-left px-4 py-4">Exchange</th>
                <th className="text-left px-4 py-4">API key</th>
                <th className="text-left px-4 py-4">API secret</th>
                <th className="text-left px-4 py-4">Passphrase</th>
                <th className="text-left px-4 py-4">3COMMA ID</th>
                <th className="text-left px-4 py-4">Action</th>
                <th className="text-left px-4 py-4">Error</th>
              </tr>
            </thead>

            <tbody>
              {bots.length > 0 ? (
                bots.map((item) => (
                  <tr className="whitespace-nowrap " key={item._id}>
                    <td className="px-4 py-4">
                      <div>
                        <input
                          id="checkbox"
                          onChange={(e) => {
                            setChecked((prevChecked) => {
                              if (e.target.checked) {
                                // Add item if it's checked and not already in the array
                                if (!prevChecked.includes(item._id)) {
                                  return [
                                    ...prevChecked,
                                    { _id: item._id, status: true },
                                  ];
                                }
                              } else {
                                // Remove item if it's unchecked
                                return prevChecked.filter(
                                  (id) => id._id !== item._id
                                );
                              }
                              return prevChecked;
                            });
                          }}
                          type="checkbox"
                          value={item._id.includes(checked) ? true : false}
                        />
                      </div>
                    </td>
                    <td onClick={() => clickTable(item)} className="px-4 py-4">
                      {item.user.name}
                    </td>
                    <td onClick={() => clickTable(item)} className="px-4 py-4">
                      {item.strategy}
                    </td>
                    <td onClick={() => clickTable(item)} className="px-4 py-4">
                      {item.status == "ACTIVE" ? "Running" : "Stopped"}
                    </td>
                    {/* <td onClick={() => clickTable(item)} className="px-4 py-4">
                      {item.intent}
                    </td> */}
                    <td onClick={() => clickTable(item)} className="px-4 py-4">
                      {formatDate(item.createdAt, "MM-DD-YY. hh:mm a")}
                    </td>
                    <td onClick={() => clickTable(item)} className="px-4 py-4">
                      {item.exchange}
                    </td>

                    <td className="px-4 py-4 ">
                      <div className="flex items-center gap-2">
                        <span>{maskString(item.apiKey)}</span>
                        <img
                          onClick={() =>
                            copyTextToClipboard(item.apiKey, "API Key")
                          }
                          src={copy_icon}
                          className="w-4 cursor-pointer"
                          alt=""
                        />
                      </div>
                    </td>
                    <td className="px-4 py-4 ">
                      <div className="flex items-center gap-2">
                        <span>{maskString(item.apiSecret)}</span>
                        <img
                          onClick={() =>
                            copyTextToClipboard(item.apiSecret, "Secret")
                          }
                          src={copy_icon}
                          className="w-4 cursor-pointer"
                          alt=""
                        />
                      </div>
                    </td>
                    <td className="px-4 py-4 ">
                      <div className="flex items-center gap-2">
                        <span>{maskString(item.apiPassphrase)}</span>
                        <img
                          onClick={() =>
                            copyTextToClipboard(
                              item.apiPassphrase,
                              "Passphrase"
                            )
                          }
                          src={copy_icon}
                          className="w-4 cursor-pointer"
                          alt=""
                        />
                      </div>
                    </td>

                    <td className="px-4 py-4">
                      <input
                        disabled={item.comma_id}
                        className="bg-transparent border text-gray-200 rounded-full focus:outline-none focus:ring-0 px-1 py-1"
                        value={comma_id[item._id]}
                        onChange={(e) =>
                          setcomma_id({
                            ...comma_id,
                            [item._id]: e.target.value,
                          })
                        }
                        onFocus={() => setSelected(item._id)}
                      />
                    </td>

                    <td className="px-4 py-4">
                      <button
                        disabled={
                          comma_id[item._id] === undefined ||
                          comma_id[item._id] === ""
                        }
                        onClick={(e) => enableBot(item)}
                        className={`bg-[#d9d9d9] text-black ${
                          (comma_id[item._id] === undefined ||
                            comma_id[item._id] === "") &&
                          "opacity-50 cursor-not-allowed"
                        }  px-4 py-2 rounded-md text-md`}
                      >
                        START
                      </button>
                      {/* <Select
                        // disabled={item.intent == "FULFILLED" ? true : false}
                        style={style_select_picker}
                        placeholder="Select Action"
                        options={[
                          { id: "1", name: "Stop", label: "Stop" },
                          { id: "2", name: "Start", label: "Start" },
                        ]}
                        labelField="name"
                        valueField="id"
                        // values={[
                        //   {
                        //     id: item.status == "ACTIVE" ? "2" : "1",
                        //     name: item.status == "ACTIVE" ? "Start" : "Stop",
                        //   },
                        // ]}
                        dropdownHeight={"110px"}
                        separator={false}
                        onChange={(values) => {
                          values[0].name == "Start"
                            ? enableBot(item)
                            : disableBot(item);
                        }}
                        backspaceDelete={false}
                        keepSelectedInList={true}
                      /> */}
                    </td>

                    <td className="px-4 py-4">
                      <Select
                        // disabled={item.intent == "FULFILLED" ? true : false}
                        style={style_select_picker}
                        placeholder="Select Error"
                        options={[
                          {
                            id: "1",
                            name: "Insufficient funds",
                            label: BOT_ERROR_STATUS.INSUFFICIENT_FUNDS,
                          },
                          {
                            id: "2",
                            name: "Bad API",
                            label: BOT_ERROR_STATUS.BAD_API,
                          },
                          {
                            id: "3",
                            name: "No trade permission",
                            label: BOT_ERROR_STATUS.NO_TRADE_PERMISSION,
                          },
                        ]}
                        labelField="name"
                        valueField="id"
                        // values={error}
                        dropdownHeight={"110px"}
                        separator={false}
                        onChange={(values) => {
                          notifyUser(item, values);
                        }}
                        backspaceDelete={false}
                        keepSelectedInList={true}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={12}
                    className="text-center sm:text-lg mt-20 font-semibold opacity-50 font-public-sans py-12"
                  >
                    Nothing is here yet!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Modal
          closeModal={onClose}
          isOpen={isOpen}
          onOpen={onOpen}
          onToggle={onToggle}
        >
          <div className="w-[310px] p-5 bg-[#5b5b5b]">
            <p className="mb-5 text-white ">
              Are you sure you want to delete the selected requests?
            </p>
            <div className="flex justify-evenly items-center">
              <button
                onClick={handleDelete}
                className="bg-[#d9d9d9] w-[100px] px-4 py-2 rounded-md text-sm"
              >
                Yes
              </button>
              <button
                onClick={onClose}
                className="bg-[#d9d9d9] w-[100px] px-4 py-2 rounded-md text-sm"
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      </Wrapper>
    </MainWrapper>
  );
}

export default Main;
