import React, { useEffect, useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import Loader from "../Loader";
import { useLoading, useLocalStorage } from "../../hooks";
import MainWrapper from "../MainWrapper";
import Header from "../Dashboard/Header";
import Sidebar from "../Dashboard/Sidebar";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import Modal from "../Modal";
import useDisclosure from "../../hooks/useDisclosure";
import DebouncedInput from "../DebouncedInput";
import useFuzzy from "../../hooks/useFuzzy";
import { style_select_picker } from "../../utilities";
import {
  FetchBots,
  MarginCall,
  FillBotIntent,
  SetBotIntent,
  SendInvoices,
} from "../../API";

function Main() {
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [authToken] = useLocalStorage("authToken", "");
  const [bots, setBots] = useState([]);
  const [unfilteredBots, setUnfilteredBots] = useState([]);
  const [filter, setFilter] = useState("");
  const [marginCall, setMarginCall] = useState();
  const [botIntent, setBotIntent] = useState();
  const [checked, setChecked] = useState([]);

  const { isOpen, onClose, onOpen, onToggle, setOpen } = useDisclosure();
  const {
    isOpen: isOpen1,
    onClose: onClose1,
    onOpen: onOpen1,
    onToggle: onToggle1,
    setOpen: setOpen1,
  } = useDisclosure();

  const { search } = useFuzzy(unfilteredBots, {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ["strategy", "exchange", "name", "intent"],
  });

  const clickTable = (userId) => {
    window.open(`/bots-management/user-details?user_id=${userId}`, "_self");
  };

  const fetchBots = async () => {
    startLoading();
    const res = await FetchBots(authToken);
    if (res?.success) {
      const botRes = res.bots.sort().map((item) => ({
        ...item,
        name: item.user.name,
      }));

      setBots(botRes);
      setUnfilteredBots(botRes);
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const sendInvoices = async (status) => {
    startLoading();

    const res = await SendInvoices(authToken);

    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
      setChecked([]);
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const handleMarginCall = async (status) => {
    if (checked.length < 1) return;
    startLoading();

    const res = await MarginCall(
      {
        bots: checked.map((id) => ({ _id: id, status })),
      },
      authToken
    );

    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
      setChecked([]);
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  useEffect(() => {
    if (!authToken) {
      toast.error("Please sign in to continue", { position: "top-center" });
      setTimeout(() => {
        window.open("/", "_self");
      }, 3000);
    } else {
      fetchBots();
    }
  }, []);

  useEffect(() => {
    if (checked.length > 0) {
      onOpen1();
    }
  }, [marginCall]);

  useEffect(() => {
    if (checked.length > 0) {
      onOpen();
    }
  }, [botIntent]);

  useEffect(() => {
    if (filter) {
      const filterRes = search(filter);
      setBots(filterRes.map((data) => data.item));
      setChecked([]);
    } else {
      setBots(unfilteredBots);
    }
  }, [filter]);

  const handleSellectAll = (e) => {
    if (e.target.checked) {
      setChecked(bots.map((item) => item._id));
    } else {
      setChecked([]);
    }
  };

  const handleDone = async (e, bot_id) => {
    e.stopPropagation();
    startLoading();
    const res = await FillBotIntent({ bot_id }, authToken);
    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
      fetchBots();
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const updateBotIntents = async () => {
    if (checked.length < 1) return;
    startLoading();
    const res = await SetBotIntent(
      { bots: checked, intent: botIntent.value },
      authToken
    );
    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
      fetchBots();
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  return (
    <MainWrapper>
      <Sidebar active={{ id: "2", href: "/bots-management" }} />
      <Header />
      <Wrapper style={"px-4 py-4 rounded-xl relative bg-[#292C3C]"}>
        <Loader isLoading={isLoading} />
        <div className="bg-[#151924] py-8 px-6 rounded-xl overflow-x-auto">
          <div className="flex items-center gap-5 w-full mb-5">
            <div className="flex flex-col justify-center items-center">
              <p className="">Select All</p>
              <input
                className=""
                type="checkbox"
                name=""
                id=""
                onChange={(e) => handleSellectAll(e)}
                checked={checked.length > 0 && checked.length === bots.length}
              />
            </div>

            <Select
              //disabled={item.intent == "FULFILLED" ? true : false}
              style={style_select_picker}
              options={[
                {
                  id: "1",
                  name: "Margin Call Enabled",
                  status: true,
                },
                {
                  id: "2",
                  name: "Margin Call Disabled",
                  status: false,
                },
              ]}
              labelField="name"
              valueField="id"
              values={marginCall}
              placeholder="Margin Call"
              dropdownHeight={"110px"}
              separator={false}
              onChange={(values) => {
                setMarginCall(values[0]);
              }}
              backspaceDelete={false}
              keepSelectedInList={true}
            />

            <Select
              //disabled={item.intent == "FULFILLED" ? true : false}
              style={style_select_picker}
              options={[
                {
                  id: "1",
                  name: "ENABLE",
                  value: "RESUME",
                },
                {
                  id: "2",
                  name: "DISABLE",
                  value: "SOFT_STOP",
                },
              ]}
              labelField="name"
              valueField="value"
              values={botIntent}
              placeholder="Bot Action"
              dropdownHeight={"110px"}
              separator={false}
              onChange={(values) => {
                setBotIntent(values[0]);
              }}
              backspaceDelete={false}
              keepSelectedInList={true}
            />

            <div className="flex justify-center items-center gap-3">
              <p className="">Filter:</p>
              <DebouncedInput
                type="text"
                name=""
                id=""
                color=""
                className="outline-none px-3 text-[#151924] w-[200px] h-[30px] rounded-lg"
                onChange={(value) => setFilter(value)}
                value={filter}
              />
            </div>
          </div>
          <table className="bg-primary rounded-lg min-w-full">
            <thead>
              <tr className="border-b-[0.5px] border-[#fff] whitespace-nowrap">
                <th className="text-left px1 py-4">{""}</th>
                <th className="text-left px-4 py-4">User</th>
                <th className="text-left px-4 py-4">Strategy</th>
                <th className="text-left px-4 py-4">Status</th>
                <th className="text-left px-4 py-4">Exchange</th>
                <th className="text-left px-4 py-4">Request</th>
                <th className="text-left px-4 py-4">Action</th>
              </tr>
            </thead>

            <tbody>
              {bots.length > 0 ? (
                bots.map((item) => (
                  <tr
                    className="whitespace-nowrap cursor-pointer"
                    key={item._id}
                  >
                    <td className="px-4 py-4">
                      <div>
                        <input
                          id="checkbox"
                          onChange={(e) => {
                            setChecked((prevChecked) => {
                              if (e.target.checked) {
                                // Add item if it's checked and not already in the array
                                if (!prevChecked.includes(item._id)) {
                                  return [...prevChecked, item._id];
                                }
                              } else {
                                console.log("unchecked");
                                // Remove item if it's unchecked
                                return prevChecked.filter(
                                  (id) => id !== item._id
                                );
                              }
                              return prevChecked;
                            });
                          }}
                          type="checkbox"
                          checked={checked.includes(item._id)}
                        />
                      </div>
                    </td>
                    <td
                      onClick={() => clickTable(item.user._id)}
                      className="px-4 py-4"
                    >
                      {item.user?.name}
                    </td>
                    <td
                      onClick={() => clickTable(item.user._id)}
                      className="px-4 py-4"
                    >
                      {item.strategy}
                    </td>
                    <td
                      onClick={() => clickTable(item.user._id)}
                      className="px-4 py-4"
                    >
                      {item.status == "ACTIVE" ? "Running" : "Stopped"}
                    </td>

                    <td
                      onClick={() => clickTable(item.user._id)}
                      className="px-4 py-4"
                    >
                      {item.exchange}
                    </td>
                    <td
                      onClick={() => clickTable(item.user._id)}
                      className="px-4 py-4"
                    >
                      {item.intent === "NIL" ? "" : item.intent}
                    </td>
                    <td
                      onClick={() => clickTable(item.user._id)}
                      className="px-4 py-4"
                    >
                      <button
                        disabled={item.intent === "NIL"}
                        onClick={(e) => handleDone(e, item._id)}
                        className={`bg-[#d9d9d9] text-black ${
                          item.intent === "NIL" &&
                          "opacity-50 cursor-not-allowed"
                        }  px-4 py-2 rounded-md text-md`}
                      >
                        DONE
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={10}
                    className="text-center sm:text-lg mt-20 font-semibold opacity-50 font-public-sans py-12"
                  >
                    Nothing is here yet!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Modal
          closeModal={onClose1}
          isOpen={isOpen1}
          onOpen={onOpen1}
          onToggle={onToggle1}
        >
          <div className="w-[310px] p-5 bg-[#5b5b5b]">
            <p className="mb-5 text-white ">
              Are you sure you want to send send a margin call to{" "}
              {checked.length} accounts?
            </p>
            <div className="flex justify-evenly items-center">
              <button
                onClick={() => {
                  handleMarginCall(marginCall.status);
                  onClose1();
                }}
                className="bg-[#d9d9d9] w-[100px] px-4 py-2 rounded-md text-sm"
              >
                Yes
              </button>
              <button
                onClick={onClose1}
                className="bg-[#d9d9d9] w-[100px] px-4 py-2 rounded-md text-sm"
              >
                No
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          closeModal={onClose}
          isOpen={isOpen}
          onOpen={onOpen}
          onToggle={onToggle}
        >
          <div className="w-[310px] p-5 bg-[#5b5b5b]">
            <p className="mb-5 text-white ">
              Are you sure you want to {botIntent?.name} {checked.length}{" "}
              accounts?
            </p>
            <div className="flex justify-evenly items-center">
              <button
                onClick={() => {
                  updateBotIntents(botIntent);
                  onClose();
                }}
                className="bg-[#d9d9d9] w-[100px] px-4 py-2 rounded-md text-sm"
              >
                Yes
              </button>
              <button
                onClick={onClose}
                className="bg-[#d9d9d9] w-[100px] px-4 py-2 rounded-md text-sm"
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      </Wrapper>
    </MainWrapper>
  );
}

export default Main;
